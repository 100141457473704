export const environment = {
  production: false,
  appVersion: '1.0.0',
  supraApi: 'https://api-stg.supra.la',
  cognitoUserPoolId: 'us-east-2_o29wDhWcB',
  cognitoClientId: 'asc46pp67sue634k344vfjvja',
  cobreRedirectUrl: 'https://stg-app.supra.la/conversion/payment-confirmation',
  complianceUrl: 'https://onboarding.supra.la',
  apiGatewayUrl: 'https://stg.api-public.supra.la/',
};
